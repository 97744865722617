.background {
    background-color: #F9FAFC;
    background-image: url(../Images/background.svg);
    background-repeat: repeat;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom: 0;
}

.auth {
    max-width: 512px;
    background-color: #fff;
    border:1px solid #EAEAEA;
    margin:auto;
    margin-top: 60px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding:70px;
    text-align: center;
}

.auth h1 {
    font-weight: 400;
    color:#1F2D3D;
    font-size: 32px;
    margin-bottom: 12px;
}

.auth img {
    margin-bottom: 22px;
    width: 56px;
    height:56px;
}

.auth p {
    font-size: 18px;
    color:#738195;
    line-height: 29px;
}

.auth input[type=email], .auth input {
    padding:22px;
    color:#1F2D3D;
    font-weight: 400;
    font-size: 18px;
    display: block;
    width: 100%;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border:1px solid #E0E6ED;
    margin:24px 0;
    -webkit-transition: border 0.1s linear;
    -moz-transition: border 0.1s linear;
    -o-transition: border 0.1s linear;
    transition: border 0.1s linear;
} 

.auth input[type=email]:focus, .auth input:focus {
    border: 1px solid #8492A6;
    outline: 0;
}

.no-margin {
    margin:0 !important;
}
  

  ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #677486;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #677486;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #677486;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #677486;
}
::-ms-input-placeholder { /* Microsoft Edge */
   color:    #677486;
}

::placeholder { /* Most modern browsers support this now. */
   color:    #677486;
}

.auth button {
    width: 100%;
    padding:22px;
    color:#fff;
    background-color: #592DEA;
    font-weight: 400;
    font-size: 18px;
    display: block;
    outline: 0;
    width: 100%;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border:0;
    margin-bottom: 24px;
    -webkit-transition: background-color 0.1s linear;
    -moz-transition: background-color 0.1s linear;
    -o-transition: background-color 0.1s linear;
    transition: background-color 0.1s linear;
}

.auth button:hover {
    background-color: #7E5BEF;
    cursor: pointer;
}

.auth a {
    color:#592DEA;
    border-bottom: 1px dashed #592DEA;
    text-decoration: none;
    -webkit-transition: border 0.1s linear;
    -moz-transition: border 0.1s linear;
    -o-transition: border 0.1s linear;
    transition: border 0.1s linear;
}

.auth a:hover {
    border-bottom: 1px solid #592DEA;
}

.disclaim {
    display: block;
    margin-top: 20px;
    text-align: center;
    font-size: 12px;
    color:#738195;
}

.success {
    background-color: #DBF1E5;
    padding:18px;
    border:1px solid #0F9F4F;
    color:#0F9F4F;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    margin-top: 24px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.error {
    background-color: #FFF0F0;
    padding:18px;
    border:1px solid #FF4949;
    color:#FF4949;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    margin-top: 24px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.message {
    background-color: #E6DFFC;
    padding:18px;
    border:1px solid #592DEA;
    color:#592DEA;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    margin-top: 24px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.loader {
    width: 48px;
    height: 48px;
    margin:24px 0;
    border: 5px solid #592DEA;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 

label {
    text-align: left !important;
    font-size: 12px;
    color:#738195;
    display: block;
    margin-top: 18px;
    padding:4px 0;
}

.smaller-top-padding {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}