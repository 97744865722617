@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&display=swap');
* {
    padding:0;
    margin:0;
}
body {
    font-family: 'Open Sans', sans-serif;
    padding:0;
    margin:0;
}

html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }