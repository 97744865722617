.navigation {
    position:fixed;
    padding:12px 40px;
    top:0;
    left:0;
    background-color: #fff;
    border-bottom: 1px solid #EAEAEA;
    width: 100%;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
  }

.avatarBorder {
    display: inline-block;
    background-color: #fff;
    padding:2px;
    border:1px solid #EAEAEA;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    float: right;
    -webkit-transition: border 0.1s linear;
    -moz-transition: border 0.1s linear;
    -o-transition: border 0.1s linear;
    transition: border 0.1s linear;
}
.avatarBorder:hover {
    cursor: pointer;
    border:1px solid #738195;
}
.avatar {
    width: 32px;
    height:32px;
    background-color: #FF7CE5;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    font-size: 0;
    text-align: center;
}
.avatar::first-letter {
    font-size:14px;
    color: #fff;
    text-transform: uppercase;
    line-height: 32px;
}
.szh-menu-container {
    right: 40px;
    margin-right: 40px !important;
}
.szh-menu {
    width: 150px;
    background-color: #fff;
    padding:40px;
    list-style-type: none;
    margin: 0;
    padding:0;
    right:40px;
    padding: 12px;
    text-align: left;
    left:-114px !important;
    top:40px !important;
    border:1px solid #EAEAEA;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.szh-menu__item {
    padding:12px;
    outline: 0;
    color:#1F2D3D;  
}
.szh-menu__item:hover {
    background-color: #EFF2F7;
    cursor: pointer;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.logo {
    float: left;
}

.logo img {
    height:36px;
    display: block;
}

.margin-top-large {
    margin-top: 85px !important;
}

.margin-top-small {
    margin-top: 30px;
}